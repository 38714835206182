import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./CaseStudyResultsBlock.module.css";

const CaseStudyResultsBlock = ({ block }) => {
  const bp = useBreakpoint();

  const bgImage = {
    data: block.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.backgroundImage?.altText || ``,
  };

  return (
    <>
      <section className={`${s.block} position-relative`} >
        {!!bgImage?.data && <GatsbyImage
          className={`${s.bg} mw-100`}
          loading="eager"
          placeholder="transparent"
          image={bgImage.data}
          alt={bgImage.alt} />}
        <div className={s.bgOverlay}></div>
        <div className={`container position-relative`}>
          <div className='row justify-content-center'>
            <div className={`${s.leftColumn} col-xxl-3 col-md-6`}>
              {!!block?.title && <h2 className={s.title}>{parse(block?.title)}</h2>}
              {!!block?.description && <div className={s.description}>{parse(block?.description)}</div>}
            </div>
            {block?.items?.length > 0 && <>
              {block?.items?.map((item, i) => {
                return <div key={i} className='col-xxl-3 col-md-6'>
                  <Item item={item} />
                </div>
              })}
            </>}
          </div>
          {!!block?.showArrow && <div className={s.arrow}>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="100" viewBox="0 0 15 100" fill="none">
              <path d="M6.89348 99.5438C7.28401 99.9343 7.91717 99.9343 8.3077 99.5438L14.6717 93.1798C15.0622 92.7893 15.0622 92.1561 14.6717 91.7656C14.2811 91.3751 13.648 91.3751 13.2574 91.7656L7.60059 97.4225L1.94374 91.7656C1.55321 91.3751 0.920047 91.3751 0.529522 91.7656C0.138998 92.1561 0.138998 92.7893 0.529522 93.1798L6.89348 99.5438ZM6.60059 0.0369873L6.60059 98.8367L8.60059 98.8367L8.60059 0.0369873L6.60059 0.0369873Z" fill="#C5C5C5" />
            </svg>
          </div>}
        </div>
      </section>
    </>
  );
};

const Item = ({ item }) => {

  return <div className={s.item}>
    <div className='d-flex flex-column align-items-start'>
      {!!item.value && <div className={s.itemValue}>{parse(item.value)}</div>}
      {!!item.title && <div className={s.itemTitle}>{parse(item.title)}</div>}
    </div>
    {!!item.description && <div className={s.itemDescription}>{parse(item.description)}</div>}
  </div>
};

export default CaseStudyResultsBlock;
