import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./CaseStudyActionsBlock.module.css";

const CaseStudyActionsBlock = ({ block }) => {
  const bp = useBreakpoint();

  return (
    <>
      <section className={`${s.block} ${block?.showArrow ? s.hasArrow : ''} position-relative`} >
        <div className={`container position-relative`}>
          <div className='row'>
            <div className='col-12'>
              {!!block?.title && <h2 className={s.title}>{parse(block?.title)}</h2>}
            </div>
            {!!block?.description && <div className='col-12 col-xl-10 col-xxl-8 mt-3'>
              <div className={s.description}>
                {parse(block?.description)}
              </div>
            </div>}
          </div>
          <div className='row justify-content-center mt-3 mt-md-5' style={{ rowGap: '25px' }}>
            {block?.actions?.length > 0 && <>
              {block?.actions?.map((a, i) => {
                return <div key={i} className='col-xxl-4 col-md-6'>
                  <ActionItem action={a} index={i + 1} />
                </div>
              })}
            </>}
          </div>
          {!!block?.showArrow && <div className={s.arrow}>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="100" viewBox="0 0 15 100" fill="none">
              <path d="M6.89348 99.5438C7.28401 99.9343 7.91717 99.9343 8.3077 99.5438L14.6717 93.1798C15.0622 92.7893 15.0622 92.1561 14.6717 91.7656C14.2811 91.3751 13.648 91.3751 13.2574 91.7656L7.60059 97.4225L1.94374 91.7656C1.55321 91.3751 0.920047 91.3751 0.529522 91.7656C0.138998 92.1561 0.138998 92.7893 0.529522 93.1798L6.89348 99.5438ZM6.60059 0.0369873L6.60059 98.8367L8.60059 98.8367L8.60059 0.0369873L6.60059 0.0369873Z" fill="#C5C5C5" />
            </svg>
          </div>}
        </div>
      </section>
    </>
  );
};

const ActionItem = ({ action, index }) => {

  return <div className={s.actionItem}>
    <div className={s.number}>{index}</div>
    <div className={s.actionContent}>
      {!!action.title && <div className={s.actionTitle}>{parse(action.title)}</div>}
      {!!action.description && <div className={s.actionDescription}>{parse(action.description)}</div>}
    </div>
  </div>
};

export default CaseStudyActionsBlock;
