import parse from "html-react-parser";
import React from "react";
import * as s from "./GlobalContentBlock.module.css";
import { Link } from 'gatsby';

const GlobalContentBlock = ({ block }) => {


  let style = {};

  if (!!block?.bgColor) {
    style.backgroundColor = block?.bgColor;
  }

  return (
    <>
      <section className={`${s.contentBlock}`} style={style}>
        <div className='container py-4 py-xl-5'>
          <div className='row'>
            <div className='col-12 col-xxl-10 offset-xxl-1'>
              {!!block?.title && <h2 className={`${s.title}  px-2 px-md-0`}>{parse(block?.title)}</h2>}
            </div>
            {!!block?.content && <div className="col-12 col-xxl-10 offset-xxl-1">
              <div className={s.content}>{parse(block?.content)}</div>
            </div>}
            {!!block?.button?.url &&
              <div className='col-12 col-xxl-10 offset-xxl-1 d-flex justify-content-center'>
                <Link
                  to={block?.button?.url}
                  target={block?.button?.target}
                  className={`${s.button}`}
                >{parse(block?.button?.title)}</Link>
              </div>
            }
          </div>
        </div>
      </section >
    </>
  );
};



export default GlobalContentBlock;
