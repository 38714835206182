import { graphql } from "gatsby"
import React from "react"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../assets/css/@wordpress/block-library/build-style/style.css"
import "../assets/css/@wordpress/block-library/build-style/theme.css"
import GlobalContentBlock from "../components/GlobalContentBlock/GlobalContentBlock"
import CaseStudiesGridBlock from "../components/caseStudiesMain/CaseStudiesGridBlock/CaseStudiesGridBlock"
import CaseStudyActionsBlock from "../components/caseStudy/CaseStudyActionsBlock/CaseStudyActionsBlock"
import CaseStudyHeroBlock from "../components/caseStudy/CaseStudyHeroBlock/CaseStudyHeroBlock"
import CaseStudyProblemBlock from "../components/caseStudy/CaseStudyProblemBlock/CaseStudyProblemBlock"
import CaseStudyResultsBlock from "../components/caseStudy/CaseStudyResultsBlock/CaseStudyResultsBlock"
import CaseStudyTestimonial from "../components/caseStudy/CaseStudyTestimonial/CaseStudyTestimonial"
import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Seo from "../components/seo"
import schemaBuilder from "./../utils/schemaBuilder"

const CaseStudyTemplate = ({ data: { post, site }, location }) => {

  const schema = schemaBuilder({ page: post }, 'page', site);
  const blocks = post?.acfCaseStudy;

  return (
    <div>
      <Header />
      <main>
        <Seo
          title={post.title}
          description={post.excerpt}
          pageSeo={post}
          location={location}
          schema={schema}
        />
        <CaseStudyHeroBlock block={{ ...blocks?.mainData, tags: [...post?.terms?.nodes] }} />

        {blocks?.problemData?.show &&
          <CaseStudyProblemBlock block={blocks?.problemData} />
        }

        {blocks?.actionsData?.show &&
          <CaseStudyActionsBlock block={blocks?.actionsData} />
        }
        {blocks?.resultsData?.show &&
          <CaseStudyResultsBlock block={blocks?.resultsData} />
        }



        {blocks?.testimonialData?.show &&
          <CaseStudyTestimonial block={blocks?.testimonialData} />
        }

        {blocks?.caseStudiesData?.show &&
          <CaseStudiesGridBlock block={blocks?.caseStudiesData} />
        }

        {blocks?.contentData?.show &&
          <GlobalContentBlock block={blocks?.contentData} />
        }
      </main>
      <Footer hideSubscribeBlock={false} pageTitle={`Case Study - ${post.title}`} />
    </div>
  )
}

export default CaseStudyTemplate

export const pageQuery = graphql`
  query CaseStudyById(
    $id: String!
  ) {
    site {
      siteMetadata {
        siteUrl: url
        title
      }
    }
    post: wpCaseStudy(id: { eq: $id }) {
      id
      excerpt
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
      terms {
        nodes {
          ... on WpCaseStudyTag {
            id
            name
            uri
          }
        }
      }
      acfCaseStudy {
        mainData {
          heroBanner {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          logo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          website {
            url
            title
            target
          }
          description
          links {
            link {
              url
              title
              target
            }
          }
        }
        problemData {
          show
          title
          showArrow
          content
        }
        actionsData {
          show
          title
          description
          showArrow
          actions {
            title
            description
          }
        }
        resultsData {
          show
          backgroundImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          title
          description
          items {
            arrow
            value
            title
            description
          }
        }

        testimonialData {
          show
          backgroundImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          testimonial {
            ... on WpTestimonial {
              acf_testimonial {
                position
                stars
              }
              content
              title
              featuredImage {
                node {
                  __typename
                  ... on WpMediaItem {
                    mediaItemUrl
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    altText
                  }
                }
              }
            }
          }
        }
        caseStudiesData {
          show
          title
          description
          items {
            item {
              ... on WpCaseStudy {
                id
                title
                uri
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 100)
                      }
                    }
                  }
                }
                acfCaseStudy {
                  mainData {
                    logo {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100)
                        }
                      }
                    }
                    links {
                      link {
                        url
                        title
                        target
                      }
                    }
                  }
                }
                terms {
                  nodes {
                    ... on WpCaseStudyTag {
                      id
                      name
                      uri
                    }
                  }
                }
              }
            }
          }
        }
        contentData {
          show
          bgColor
          title
          content
          button {
            url
            title
            target
          }
        }
      }
    }
  }
`
