// extracted by mini-css-extract-plugin
export var bg = "CaseStudyResultsBlock-module--bg--5fc9c";
export var bgOverlay = "CaseStudyResultsBlock-module--bgOverlay--d56ed";
export var block = "CaseStudyResultsBlock-module--block--6ba7e";
export var description = "CaseStudyResultsBlock-module--description--e81cb";
export var item = "CaseStudyResultsBlock-module--item--5d7a9";
export var itemDescription = "CaseStudyResultsBlock-module--itemDescription--df9d2";
export var itemTitle = "CaseStudyResultsBlock-module--itemTitle--9488b";
export var itemValue = "CaseStudyResultsBlock-module--itemValue--525b8";
export var leftColumn = "CaseStudyResultsBlock-module--leftColumn--f5c43";
export var title = "CaseStudyResultsBlock-module--title--0b803";