import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./CaseStudyProblemBlock.module.css";

const CaseStudyProblemBlock = ({ block }) => {
  const bp = useBreakpoint();

  return (
    <>
      <section className={`${s.block} position-relative`} >
        <div className={`container position-relative`}>
          <div className='row'>
            <div className='col-12'>
              {!!block?.title && <h2 className={s.title}>{parse(block?.title)}</h2>}
            </div>
            {!!block?.content && <div className='col-12 col-xl-10 col-xxl-8'>
              <div className={s.content}>
                {parse(block?.content)}
              </div>
            </div>}
          </div>
          {!!block?.showArrow && <div className={s.arrow}>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="101" viewBox="0 0 15 101" fill="none">
              <path d="M6.89348 99.7906C7.28401 100.181 7.91717 100.181 8.3077 99.7906L14.6717 93.4266C15.0622 93.0361 15.0622 92.4029 14.6717 92.0124C14.2811 91.6219 13.648 91.6219 13.2574 92.0124L7.60059 97.6693L1.94374 92.0124C1.55321 91.6219 0.920047 91.6219 0.529522 92.0124C0.138998 92.4029 0.138998 93.0361 0.529522 93.4266L6.89348 99.7906ZM6.60059 0.283814L6.60059 99.0835L8.60059 99.0835L8.60059 0.283813L6.60059 0.283814Z" fill="#063B6D" />
            </svg>
          </div>}
        </div>
      </section>
    </>
  );
};

export default CaseStudyProblemBlock;
