import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./CaseStudyTestimonial.module.css";

const CaseStudyTestimonial = ({ block }) => {
  const bp = useBreakpoint();

  const bgImage = {
    data: block.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.backgroundImage?.altText || ``,
  };

  const image = {
    data: block.testimonial?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.testimonial?.featuredImage?.node?.altText || ``,
  };

  return (
    <>
      <section className={`${s.block} position-relative`} >
        {!!bgImage?.data && <GatsbyImage
          className={`${s.bg} mw-100`}
          loading="eager"
          placeholder="transparent"
          image={bgImage.data}
          alt={bgImage.alt} />}
        <div className={`container position-relative`}>
          <div className='row justify-content-center'>
            {!!block?.testimonial?.content && <div className={`${s.text} col-lg-11 col-xl-10 col-xxl-8`}>{parse(block?.testimonial?.content)}</div>}
          </div>
          {!!image?.data && <div className='d-flex justify-content-center'>
            <div className={s.imageWrapper}>
              <GatsbyImage
                className={`mw-100`}
                loading="eager"
                placeholder="transparent"
                image={image.data}
                alt={image.alt} />
            </div>
          </div>}
          {!!block?.testimonial?.title && <div className={s.name}>{parse(block?.testimonial?.title)}</div>}
          {!!block?.testimonial?.acf_testimonial?.position && <div className={s.position}>{parse(block?.testimonial?.acf_testimonial?.position)}</div>}
        </div>
      </section>
    </>
  );
};

export default CaseStudyTestimonial;
