import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./CaseStudyHeroBlock.module.css";

const CaseStudyHeroBlock = ({ block }) => {
  const bp = useBreakpoint();

  const logo = {
    data: block.logo?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.logo?.altText || ``,
  };

  const heroBanner = {
    data: block.heroBanner?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.heroBanner?.altText || ``,
  };

  return (
    <>
      <section className={`${s.hero} position-relative`} >
        {!!heroBanner?.data && <GatsbyImage
          className={'mw-100'}
          loading="eager"
          placeholder="transparent"
          image={heroBanner.data}
          alt={heroBanner.alt} />}
        <div className={`container mt-5 mb-5 pb-4`}>
          <div className='row'>
            <div className={`${s.left} col-xl-3 col-lg-4`}>

              {!!logo?.data && <div className={s.logoWrapper}>
                <GatsbyImage
                  className={'mw-100'}
                  loading="eager"
                  placeholder="transparent"
                  image={logo.data}
                  alt={logo.alt} />
              </div>}
              {!!block?.website?.url && <div className={`${s.website}`}>
                <span>website:&nbsp;</span>
                <Link
                  to={block?.website?.url}
                  target={block?.website?.target}
                >{parse(block?.website?.title)}</Link>
              </div>}
            </div>
            <div className={`${s.right} col-xl-9 col-lg-8`}>
              {!!block.description && <div className={`${s.description}`}>{parse(block.description)}</div>}
              <div className={s.tags}>
                {block?.tags?.length > 0 &&
                  <>
                    {block?.tags?.map((t, i) => {
                      return <span key={i} className={s.tag} > {parse(t?.name)}</span>
                      return <Link key={i} className={s.tag} to={t?.uri}>
                        {parse(t?.name)}
                      </Link>
                    })}
                  </>
                }
                <div className='w-100 d-none d-md-block'></div>
                {block?.links?.length > 0 &&
                  <>
                    {block?.links?.map((l, i) => {
                      return <Link key={i} className={s.link} to={l?.link?.url}
                        target={l?.link?.target}>
                        {parse(l?.link?.title)}
                      </Link>
                    })}
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CaseStudyHeroBlock;
